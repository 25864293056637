import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { $host } from '../../../http';
import './NewsItemPage.css';
import { NavbarMobile } from '../../../components/navbarMobile/NavbarMobile';
import { Navbar } from '../../../components/navbar/Navbar';
import { Link } from 'react-router-dom';
import files from '../../../files/Программа круглого стола 2504.pdf'
import iconPdf from '../../../img/icon_pdf.png'

export const NewsItemPages = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [tags, setTags] = useState([]);
  const [resources, setResources] = useState([]);
  const [error, setError] = useState(null);
  const [fileUrl, setFileUrl] = useState('/src/files/Программа круглого стола 2504.pdf');
  // Функция для скачивания файла
const handleDownload = () => {
    const link = document.createElement('a');
    link.href = files;
    link.setAttribute('download', encodeURIComponent('filename')); // Используем encodeURIComponent для кодирования имени файла
    document.body.appendChild(link);
    link.click();
};

    const getFileExtension = () => {
        return fileUrl.split('.').pop().toLowerCase();
    };


  const getNewsDetail = async (id) => {
    try {
      const response = await $host.get(`/news/${id}`);
      return response.data;
    } catch (error) {
      console.error('Ошибка при получении подробной информации о новости', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const data = await getNewsDetail(id);
        setNews(data.news);
        setTags([data.newsTags]);
        setResources([data.newsResources]);
      } catch (error) {
        setError('Ошибка при загрузке подробной информации о новости');
      }
    };

    fetchNewsDetail();
  }, [id]);

  const getFileIcon = () => {
    const extension = getFileExtension();
    switch (extension) {
    case 'pdf':
        return iconPdf;
    case 'html':
        return '/path/to/html-icon.png'; 
    default:
        return null;
    }
};

  if (error) {
    return <div>{error}</div>;
  }

  if (!news) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className='resources'>
      <NavbarMobile className='learnNavbarMobile' />
      <div className="wrapper">
        <Navbar className='learnNavbar' />
        <div className="news_item">
        <h1>{news.title}</h1>
        <div className="small_info">
        <p className="date">📅 {new Date(news.createdAt).toLocaleDateString()}</p>
        <div className="tags">
        

          {tags.map(tag => (
            <Link key={tag.tag_id} to={`/tags/${tag.tag_id}`}>#{tag.Tag.title}</Link>
          ))}
        </div>
        </div>
        <p>{news.description}</p>
        <h3>Ссылки и ресурсы</h3>

        <div  className="files">
        <img src={getFileIcon()} alt="file-icon" className="file-icon" />
          {resources.map(resource => (
            
            <div style={{display:'flex', alignItems:'center',gap:'20px'}} key={resource.resources_id}>
               <div className="file-title">
              <p>{resource.NewsResource.title}</p>
              </div>
              {/* <a href={resource.NewsResource.url}>{resource.NewsResource.url}</a> */}
              <div className="file-container">
                            <button onClick={handleDownload}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M13 14.293l3.146-3.147a.5.5 0 01.708.708l-4 4a.5.5 0 01-.708 0l-4-4a.5.5 0 01.708-.708L12 14.293V3.5a.5.5 0 111 0v10.793zm7 1.207a.5.5 0 111 0v3a2.5 2.5 0 01-2.5 2.5h-12A2.5 2.5 0 014 18.5v-3a.5.5 0 111 0v3A1.5 1.5 0 006.5 20h12a1.5 1.5 0 001.5-1.5v-3z"/></svg></button>
                        </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import './SigninPage.css';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../consts';
import { fetchSignup } from '../../http/authAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAdmin, setIsAuth } from '../../store/mainReducer';

export const SigninPage = () => {
  const imagePath = `${process.env.PUBLIC_URL}/img/LoginImage.png`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth } = useSelector(state => state.main);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    birthday: '',
    phone_number: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.last_name.trim()) newErrors.last_name = 'Фамилия обязательна';
    if (!formData.first_name.trim()) newErrors.first_name = 'Имя обязательно';
    if (!formData.email.trim()) newErrors.email = 'Username обязателен';
    if (!formData.password) newErrors.password = 'Password обязателен';
    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Пароли не совпадают';
    if (!formData.phone_number.match(/^\+\d{10,15}$/)) newErrors.phone_number = 'Номер телефона должен начинаться с "+" и содержать цифры';
    if (!formData.birthday) newErrors.birthday = 'Дата рождения обязательна';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const data = await fetchSignup(formData);
        dispatch(setIsAuth(true));
        if (data.users.role_id === 1) {
          dispatch(setIsAdmin(true));
        }
        console.log('Successful login:', data);
        navigate(HOME_ROUTE);
      } catch (error) {
        console.error('Login error:', error);
        alert(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className='loginPage'>
      <div className='loginContainer'>
        <div className="loginImage">
          <img src={imagePath} alt="" />
        </div>
        <div className="login">
          <img src="./img/Back.png" alt="" onClick={() => navigate(HOME_ROUTE)} />
          <h2>Регистрация</h2>
          <form className="loginForm" onSubmit={handleLoginSubmit}>
            <p>Фамилия</p>
            <input
              type="text"
              name='last_name'
              placeholder='Фамилия'
              onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
            />
            {errors.last_name && <p className="error">{errors.last_name}</p>}
            <p>Имя</p>
            <input
              type="text"
              name='first_name'
              placeholder='Имя'
              onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
            />
            {errors.first_name && <p className="error">{errors.first_name}</p>}
            <p>Логин</p>
            <input
              type="text"
              name='email'
              placeholder='Username'
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            {errors.email && <p className="error">{errors.email}</p>}
            <p>Пароль</p>
            <input
              type="password"
              name='password'
              placeholder='Password'
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            />
            {errors.password && <p className="error">{errors.password}</p>}
            <p>Повторить пароль</p>
            <input
              type="password"
              name='confirmPassword'
              placeholder='Повторить пароль'
              onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
            />
            {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
            <p>Номер телефона</p>
            <input
              type="tel"
              name='phone'
              placeholder='Номер телефона'
              onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
            />
            {errors.phone_number && <p className="error">{errors.phone_number}</p>}
            <p>Дата рождения</p>
            <input
              type="date"
              name='birthday'
              onChange={(e) => setFormData({ ...formData, birthday: e.target.value })}
            />
            {errors.birthday && <p className="error">{errors.birthday}</p>}
            <button type="submit" disabled={loading}>
              {loading ? 'Загрузка...' : 'Зарегистрироваться'}
            </button>
          </form>
          <br/>
        </div>
      </div>
    </div>
  );
};

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Forum } from './Forum/Forum'
import { Learn } from './Learn/Learn'
import { Users } from './users/Users'
import { CreateForum } from './Forum/CreateForum'
import { EditForum } from './Forum/EditForum'
import UserBlank from './users/UserBlank'
import { RegisterPage } from './registerPage/RegisterPage'
import { Applications } from './applications/Applications'
import { Createresources } from './createresources/createresources'
import { Visit } from './visit/visit'
import { Profile } from './Profile/Profile'
import { CreateNews } from './news/createnews'
// import { UserBlank } from './users/UserBlank'

export const AdminRoutes = () => {
  return (
    <Routes>
        <Route path="/*" element={ <Navigate to="/admin/forum" /> } />
        
        <Route path="/forum" element={ <Forum /> } />
        <Route path="/forum/create" element={ <CreateForum /> } />
        <Route path="/forum/edit" element={ <EditForum /> } />
        
        <Route path="/users" element={ <Users /> } />
        <Route path="/users/edit" element={ <UserBlank /> } />
        <Route path="/users/create" element={ <UserBlank /> } />

        <Route path="/learn" element={ <Learn /> } />
        <Route path="/forum/blank" element={ <Learn /> } />

        <Route path="/register" element={ <RegisterPage /> } />

        <Route path="/createresources" element={ <Createresources /> } />
        
        <Route path="/applications" element={ <Applications /> } />
        <Route path="/news" element={ <CreateNews /> } />

        <Route path="/visit" element={ <Visit /> } />
    </Routes>
  )
}

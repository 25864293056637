import React, { useState, useEffect } from 'react';

import './applications.css'
import { $authHost } from '../../../http';

export const Applications = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await $authHost.get('/users');
        setUsers(response.data.users.filter(user => user.status === 'loading'));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setErrorMessage('An error occurred while fetching users');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleApprove = async (userId) => {
    try {
      await $authHost.put(`/confirmed/${userId}`, { status: 'true' });
      const updatedUsers = users.map(user => {
        if (user.id === userId) {
          user.status = 'true';
        }
        return user;
      });
      setUsers(updatedUsers);
      window.location.reload();
    } catch (error) {
      console.error('Error updating user status:', error);
      // Handle error
    }
  };

  const handleRoleChange = async (userId, newRoleId) => {
    try {
      await $authHost.put(`/role_update/${userId}`, { role_id: newRoleId });
      const updatedUsers = users.map(user => {
        if (user.id === userId) {
          user.role_id = newRoleId;
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error updating user role:', error);
      // Handle error
    }
  };

  const handleRoleSelect = (roleId) => {
    setSelectedRole(roleId);
  };

  return (
    <div>
      <h2>Подтверждение</h2>
      {loading ? (
        <p>Загрузка...</p>
      ) : errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        <ul >
          {users.map((user) => (
            <li className='users_apllications' key={user.id}>
              <div style={{display:'flex'}}>
                <div>{user.first_name} {user.last_name}, Статус: {user.status}, Роль: {user.role}</div>
                <select className='select_applications'
                  value={user.role_id}
                  onChange={(e) => {
                    handleRoleChange(user.id, e.target.value);
                    handleRoleSelect(e.target.value);
                  }}
                >
                  <option value="5">Доктор</option>
                  <option value="6">Ребенок</option>
                  <option value="4">Родитель</option>
                  <option value="3">Пользователь</option>
                  <option value="2">Админ</option>
                </select>
                {selectedRole === "4" && (
                  // Поле для выбора родителя
                  <select>
                    {/* Ваш код для выбора родителя */}
                  </select>
                )}
                {selectedRole === "5" && (
                  // Поле для выбора доктора
                  <select>
                    {/* Ваш код для выбора доктора */}
                  </select>
                )}
              </div>
              <button onClick={() => handleApprove(user.id)}>Подтвердить</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

import React from 'react'
import './Logo.css'
// import whiteLogo from './img/white_logo.svg';

export const Logo = () => {


  return (
    <div className='logo2'>
        {/* <img src="./img/white_logo.svg" alt="white_logo" /> */}
    </div>
  )
}

import React from 'react'
import './RegisterPage.css'
import Registerblock from './registerblock/registerblock'




export const RegisterPage = () => {

  return (
    <div className='registerPage'>
     
      <Registerblock />
      
    </div>
  )
}

import React from 'react';

export const LearnItem = ({ imageSrc, title, description }) => {
  const handleClick = () => {
    window.location.href = 'https://ustazeducation.kz';
  };

  return (
    <div className="learn-item">
      <img src={imageSrc} alt="Описание изображения" className="learn-item-image" />
      <div className="learn-item-text">
        <h2>{title}</h2>
        <br/>
        <p>{description}</p>
        <button className="learn-item-button" onClick={handleClick}>
          Подробнее
        </button>
      </div>
    </div>
  );
};

import React from 'react';
import './RasPage.css';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../../components/navbar/Navbar';
export const RasPage = () => {
  const navigate = useNavigate();
  return (
    <div className='RasPage'>
            {/* Кнопка со стрелкой "Назад" */}
            <button className='backButton' onClick={() => navigate(-1)}>
        <span className='arrow'></span>
      </button>
      <h1>Диагностика РАС по движению зрачка</h1>
      <section className='asdInfo'>
        <p>
          Диагностика расстройств аутистического спектра (РАС) является многоплановой задачей, включающей разнообразные методики и инструменты для обеспечения точности и достоверности. Одним из перспективных методов является анализ окомоторных параметров, в частности, движений зрачков, которые могут предоставить объективные данные о нейрофизиологических и когнитивных процессах.
        </p>
        <p>
          Эта инструкция предназначена для специалистов в области психиатрии, неврологии, педиатрии, врачей общей практики, клинической психологии, а также исследователей, занимающихся изучением нейрокогнитивных аспектов РАС.
        </p>
      </section>

      <section className='asdResults'>
        <h2>Результаты</h2>
        <p>
          На основе технологий движения глаз, базирующихся на алгоритме машинного обучения, была выявлена вероятность наличия схожести симптомов расстройства аутистического спектра на <strong>80%</strong>.
        </p>
        <p>
          Данное заключение не является диагнозом и должно быть правильно интерпретировано лечащим врачом после полного клинического и диагностического обследования.
        </p>
      </section>
    </div>
  );
};

import 'react-datepicker/dist/react-datepicker.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { $host } from '../../http';
import { NavbarMobile } from '../../components/navbarMobile/NavbarMobile';
import { Navbar } from '../../components/navbar/Navbar';
import './NewsPages.css';

export const NewsPages = () => {
  const [news, setNews] = useState([]);
  const [newsTags, setNewsTags] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getNews = async () => {
    try {
      const response = await $host.get(`/news`);
      return response.data;
    } catch (error) {
      console.error('Ошибка при получении новостей', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsData = await getNews();
        setNews(newsData.news);
        setNewsTags(newsData.newsTags);
      } catch (error) {
        setError('Ошибка при загрузке новостей');
      }
    };

    fetchNews();
  }, []);

  const getTagsForNews = (newsId) => {
    return newsTags.filter(tag => tag.news_id === newsId);
  };

  return (
    <div className='news_containers'>
      <NavbarMobile className='learnNavbarMobile' />
      <div className="wrapper">
        <Navbar className='learnNavbar' />
        <h1>Новости</h1>
        <div className="news">
          {error && <p>{error}</p>}
          <ul>
            {news.map(item => (
              <li key={item.id} className="news-block">
                <div className='titles'>
                  <h3>{item.title}</h3>
                  <p className="date">📅 {new Date(item.createdAt).toLocaleDateString()}</p>
                </div>
                <div className="tags">
                  {getTagsForNews(item.id).map(tag => (
                    <Link key={tag.tag_id} to={`/tags/${tag.tag_id}`}>#{tag.Tag.title}</Link>
                  ))}
                </div>
                <p>{item.description}</p>
                <div className='buttons'>
                <button onClick={() => navigate(`/news/${item.id}`)}><p>Подробнее</p></button>
                    </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

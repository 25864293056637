import React from 'react'
import './Header.css'
import { Button } from '../button/Button'
import { Navbar } from '../navbar/Navbar'
import { NavbarMobile } from '../navbarMobile/NavbarMobile'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Header = () => {

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substr(0, maxLength);
  };

  const truncateAndAddReadMore = (text, maxLength) => {
      const hasTruncated = text.length > maxLength;
      const truncatedText = hasTruncated ? text.substring(0, maxLength) : text;
      const lastSpaceIndex = truncatedText.lastIndexOf(' ');
      const truncatedTextWithReadMore = truncatedText.substring(0, lastSpaceIndex);
      const readMoreLink = hasTruncated &&  <Link to="/news/1"><br /><button className="buttonM">Подробнее</button></Link>;

      // Разделение текста на строки с использованием '<br />'
      const splitText = truncatedTextWithReadMore.split('\n').map((line, index, array) => (
          <React.Fragment key={index}>
              {line}
              {index !== array.length - 1 && <br />} {/* Добавить <br /> после каждой строки, кроме последней */}
          </React.Fragment>
      ));

      return (
          <>
              {splitText}
              {readMoreLink}
          </>
      );
  };

  let maxLength;
    if (window.innerWidth <= 800) {
        maxLength = 400; // Для размера экрана менее или равного 800px
    } else if (window.innerWidth <= 750) {
        maxLength = 200; // Для размера экрана менее или равного 700px
    } else {
        maxLength = 500; // Для всех остальных размеров экрана
    }
  
  return (
    <header className='header' id='header'>
        <Navbar />
        <NavbarMobile />
        <h1>Добро пожаловать на наш сайт!</h1>
        <p>{truncateAndAddReadMore(`
                                    посвящённый поддержке и пониманию людей с аутизмом. Здесь вы найдёте полезную информацию, ресурсы и истории, которые помогут вам лучше понять мир аутизма. Наша цель — создать сообщество, где каждый сможет найти поддержку и необходимые знания для того, чтобы жить полноценной и счастливой жизнью. Мы рады, что вы с нами!\n\n\n\n\n\n\n\n\n\n\n\n
        `, maxLength)}</p>
    </header>
  )
}

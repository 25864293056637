import React from 'react'
import './Profile.css';

export const Contact = () => {
  return (
    <div className='Contact'> 
      <h1>Контакты</h1>

      <div className='containers_wrap'>
        {/* <div className='containers'>
          <img src='/img/Ellipse 16.png'></img>
          <div>
          <p>Антон Майлов</p>
          <p>роль: отец</p>
          </div>
        </div>
        <div className='containers'>
          <img src='/img/Ellipse 16.png'></img>
          <div>
          <p>Антон Майлов</p>
          <p>роль: мать</p>
          </div>
          </div>
        <div className='containers'>
          <img src='/img/Ellipse 16.png'></img>
          <div>
          <p>Антон Майлов</p>
          <p>роль: врач</p>
          </div>
          </div> */}
          <p>У вас нет контактов</p>
      </div>
    </div>
  )
}

import React from 'react';
import './TestsPage.css';
import { useNavigate } from 'react-router-dom';
import { RAS_ROUTE } from '../../consts';

export const TestsPage = () => {
  const navigate = useNavigate();

  return (
    <div className='testsPage'>
      {/* Кнопка со стрелкой "Назад" */}
      <button className='backButton' onClick={() => navigate(-1)}>
        <span className='arrow'></span>
      </button>
      <h1>Тесты</h1>

      {/* M-CHAT тест */}
      <section className='testSection'>
        <h2>M-CHAT тест</h2>
        <p>
          <strong>Инструкции по использованию:</strong><br />
          M-CHAT-R/F предназначен для совместного использования с M-CHAT-R; M-CHAT-R для скрининга детей в возрасте от 16 до 30 месяцев, с целью оценки риска расстройств аутистического спектра (РАС). Пользователям необходимо знать, что даже с использованием последующих вопросов значительное количество детей, выдающих результаты «неудача» в процессе работы с M-CHAT- R, не получают диагноз РАС; однако для этих детей существуют риски других расстройств или задержек развития и, таким образом, последующая проверка должна проводиться для любого ребенка, чьи результаты оказались положительными.
        </p>
        <button onClick={() => { /* Код для открытия M-CHAT теста */ }}>
          Пройти M-CHAT тест
        </button>
      </section>

      {/* Тест Бека на уровень депрессии */}
      <section className='testSection'>
        <h2>Тест Бека на уровень депрессии</h2>
        <button onClick={() => { window.location.href = 'https://psytests.org/depr/bdi-run.html'; }}>
          Пройти тест Бека
        </button>
      </section>

      {/* Диагностика РАС по движению зрачка */}
      <section className='testSection'>
        <h2>Диагностика РАС по движению зрачка</h2>
        <p>
          <strong>Преамбула:</strong><br />
          Диагностика расстройств аутистического спектра (РАС) является многоплановой задачей, включающей разнообразные методики и инструменты для обеспечения точности и достоверности. Одним из перспективных методов является анализ окомоторных параметров, в частности, движений зрачков, которые могут предоставить объективные данные о нейрофизиологических и когнитивных процессах. Эта инструкция предназначена для специалистов в области клинической психологии, неврологии и офтальмологии, а также исследователей, занимающихся изучением нейрокогнитивных аспектов РАС.
        </p>
        <button onClick={() => navigate(RAS_ROUTE)}>
          Начать диагностику
        </button>
      </section>
    </div>
  );
};

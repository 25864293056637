export const HOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login' 
export const LEARN_ROUTE = '/learn'
export const FORUM_ROUTE = '/forum'
export const FORUM_ITEM = '/item'
export const ADMIN_ROUTE = '/admin'
export const RESOURCES_ROUTE = '/resources'
export const TOPICS_ROUTE = '/topics'
export const NEWS_ROUTE = '/news'
export const PROFILE_ROUTE = '/profile'
export const SIGNIN_ROUTE = '/signin'
export const TESTS_ROUTE = '/tests'
export const RAS_ROUTE = '/ras'
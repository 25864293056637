import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../consts';
import { fetchLogin } from '../../http/authAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAdmin, setIsAuth } from '../../store/mainReducer';

export const LoginPage = () => {
  const imagePath = `${process.env.PUBLIC_URL}/img/LoginImage.png`;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuth } = useSelector((state) => state.main);

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const data = await fetchLogin(formData);

      // Выполняем действия после успешной авторизации
      dispatch(setIsAuth(true));
      if (data.user.Role.name === 'admin') {
        dispatch(setIsAdmin(true));
      }
      console.log('Successful login:', data);
      navigate(HOME_ROUTE); // Перенаправляем на домашнюю страницу
    } catch (error) {
      // Обработка ошибок авторизации
      console.error('Login error:', error);
      // Можно добавить сообщение об ошибке для пользователя
      alert("Логин или пароль неверны");
    }
  };

  return (
    <div className='loginPage'>
      <div className='loginContainer'>
        <div className="loginImage">
          <img src={imagePath} alt="" />
        </div>
        <div className="login">
          <img src="./img/Back.png" alt="" onClick={() => navigate(HOME_ROUTE)} />
          <h2>Авторизация</h2>
          <form action='' className="loginForm">
            <p>Логин</p>
            <input 
              type="text" 
              name='username' 
              placeholder='username' 
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
            />
            <p>Пароль</p>
            <input 
              type="password" 
              name='password' 
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              placeholder='Password' 
            />
            <button 
              onClick={handleLoginSubmit}
            >
              Войти
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import './LearnPage.css';
import { Filter } from './filter/Filter';
import { Navbar } from '../../components/navbar/Navbar';
import { LearnBlock } from './learnBlock/LearnBlock';
import { LearnItem } from './learnItem/LearnItem'; // Если это отдельный файл, оставьте его импорт
import { NavbarMobile } from '../../components/navbarMobile/NavbarMobile';

export const LearnPage = () => {
  return (
    <div className='learnPage'>
      <NavbarMobile className='learnNavbarMobile' />
      <div className="wrapper">
        <Navbar className='learnNavbar' />
        
        {/* <Filter /> <LearnBlock /> */}
        <h1>Обучение</h1>
        <LearnItem 
          imageSrc="./img/learnItemImage.png"
          title="ОБРАЗОВАТЕЛЬНАЯ ПРОГРАММА КУРСОВ ПОВЫШЕНИЯ КВАЛИФИКАЦИИ ПЕДОГАГИЧЕСКИХ КАДРОВ"
          description="Добро пожаловать на курс повышения квалификации, посвящённый обучению и поддержке детей с аутизмом. Этот курс создан для того, чтобы помочь педагогам лучше понять особенности аутизма и развить навыки, необходимые для успешной работы с учениками, имеющими расстройства аутистического спектра.\n В ходе обучения вы узнаете о признаках аутизма, подходах к обучению и методах, которые помогут создать благоприятную образовательную среду для каждого ученика. Мы рассмотрим стратегии индивидуализации обучения, эффективного взаимодействия с детьми, а также способы работы с их родителями и коллегами. Наша цель — помочь вам стать более уверенным и осведомлённым педагогом, готовым к работе с разнообразными учениками и способным сделать образование доступным для всех. Мы верим, что с вашими новыми знаниями и умениями вы сможете сделать значимый вклад в жизнь детей с аутизмом и их семей."
        />
        <LearnItem 
          imageSrc="./img/learnItemImage2.png"
          title="РЕАБИЛИТАЦИЯ ДЕТЕЙ С РАССТРОЙСТВОМ АУТИСТИЧЕСКОГО"
          description="Уважаемые коллеги! Приглашаем вас принять участие в специализированном обучающем курсе по реабилитации детей с расстройствами аутистического спектра (РАС). Этот курс предназначен для медицинских специалистов, желающих углубить свои знания и освоить современные методы реабилитации, направленные на улучшение качества жизни детей с РАС. В рамках курса вы получите возможность изучить актуальные подходы к диагностике, интервенции и терапии для детей с РАС. Ведущие эксперты поделятся своими знаниями и опытом, а также представят клинические случаи и практические рекомендации, которые помогут вам в повседневной работе."
        />
      </div>
    </div>
  );
};

export default LearnPage;

import { $authHost, $host } from "./index";

export const fetchLogin = async (formData) => {
  try {
    const response = await fetch('http://localhost:5000/api/signin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 201) {
      // Если статус 201, возвращаем JSON ответ и сохраняем токен
      const data = await response.json();
      localStorage.setItem("token", data.token);
      return data;
    } else {
      // Если статус не 201, читаем текст ошибки и выбрасываем исключение
      const errorText = await response.text();
      throw new Error(`Login failed. Status: ${response.status}. Error: ${errorText}`);
    }
  } catch (error) {
    // Обработка ошибок запроса
    console.error('Ошибка при выполнении запроса:', error);
    throw error; // Пробрасываем ошибку дальше
  }
};

export const fetchSignup = async (formData) => {
  try {
    const response = await fetch('http://localhost:5000/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 201) {
      // Если статус 201, возвращаем JSON ответ
      return response.json();
    } else {
      // Если статус не 201, читаем текст ошибки и выбрасываем исключение
      const errorText = await response.text();
      throw new Error(`Signup failed. Status: ${response.status}. Error: ${errorText}`);
    }
  } catch (error) {
    // Обработка ошибок запроса
    console.error('Ошибка при выполнении запроса:', error);
    throw error; // Пробрасываем ошибку дальше
  }
};


export const fetchLogout = async () => {
  const { data } = await $authHost.get("/signout");
  localStorage.removeItem("token");
  return data;
};


// import { $authHost, $host } from '.';
// import { setIsAuth } from './path/to/your/mainReducer';

// export const fetchLogin = async (formData, dispatch) => {
//   try {
//     const { data } = await $host.post("/signin", formData);
//     localStorage.setItem("token", data.token);
//     dispatch(setIsAuth(true)); // Устанавливаем isAuth в true
//     return data;
//   } catch (error) {
//     console.error('Login error:', error);
//     throw error; // Обрабатываем ошибку или прокидываем выше
//   }
// };

// export const fetchLogout = async (dispatch) => {
//   try {
//     const { data } = await $authHost.get("/signout");
//     localStorage.removeItem("token");
//     dispatch(setIsAuth(false)); // Устанавливаем isAuth в false
//     return data;
//   } catch (error) {
//     console.error('Logout error:', error);
//     throw error; // Обрабатываем ошибку или прокидываем выше
//   }
// };